import React from "react"
import styled from "styled-components"
import Grid from "../zzz/layout/grid"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import Heading from "../zzz/organisms/heading"
import { GatsbyImage } from "gatsby-plugin-image"

const PaymentProviders = ({ location }) => {
  const {
    ccImage: {
      childImageSharp: { gatsbyImageData: credit_card },
    },
    feverImage: {
      childImageSharp: { gatsbyImageData: fevertree },
    },
    payflexImage: {
      childImageSharp: { gatsbyImageData: payflex },
    },
    zeropayImage: {
      childImageSharp: { gatsbyImageData: zeropay },
    },
    payjustnowImage: {
      childImageSharp: { gatsbyImageData: payjustnow },
    },
    mobicredImage: {
      childImageSharp: { gatsbyImageData: mobicred },
    },
    masterpassImage: {
      childImageSharp: { gatsbyImageData: masterpass },
    },
    payfastEftImage: {
      childImageSharp: { gatsbyImageData: instantEft },
    },
    ozowImage: {
      childImageSharp: { gatsbyImageData: ozow },
    },
    floatImage: {
      childImageSharp: { gatsbyImageData: float },
    },
    page: page,
  } = useStaticQuery(QUERY)

  // const pageData = useStaticQuery(QUERY)

  // console.log(page)

  const seo = page.seo

  let PaymentMethodItems = [
    {
      img: credit_card,
      alt: "Credit Card",
      id: "creditcard_id",
      description: "All major credit and debit cards accepted.",
    },
    {
      img: instantEft,
      alt: "Instant EFT",
      id: "instanteft_id",
      description:
        "Manual Electronic Fund Transfer (EFT). Choosing this option allows you to purchase and manually transfer to the Mattress Warehouse banking account. Find our banking details on your order confirmation email that will be sent to you.",
    },
    {
      img: ozow,
      alt: "Ozow",
      id: "ozow_id",
      description:
        "Ozow, making online payments easy, convenient & secure. Shop online using Ozow and pay online in seconds",
    },
    {
      img: masterpass,
      alt: "MasterPass",
      id: "masterpass_id",
      description:
        "Scan to Pay, underpinned by Masterpass technology is a safe and convenient way to pay for goods and services by scanning a QR code.",
    },
    {
      img: zeropay,
      alt: "ZeroPay",
      id: "zeropay_id",
      description:
        "Pay for your shopping in 3 x Zero fees, Zero interest payments.",
    },
    {
      img: payjustnow,
      alt: "PayJustNow",
      id: "payjustnow_id",
      description:
        "Receive your purchase today and pay later! PayJustNow enables you to pay for your purchase over 3 equal, zero interest installments.",
    },
    {
      img: payflex,
      alt: "PayFlex",
      id: "payflex_id",
      description:
        "Checkout with Payflex and pay only 25% of the purchase price today and pay the rest over 6 weeks at no additional cost (zero fees, zero interest*).",
    },
    {
      img: float,
      alt: "Pay with Float",
      id: "float_id",
      description:
        "Pay in up to 3 monthly instalments using your existing credit card. Zero interest. Zero fees. Zero new debt.",
    },
    {
      img: fevertree,
      alt: "The Mattress Warehouse Accounts by FeverTree",
      id: "fevertree_id",
      description: "Pay up to 24 monthly instalments with a credit account.",
    },
    // {
    //   img: mobicred,
    //   alt: "MobiCred",
    //   id: "mobicred_id",
    //   description: "Make online shopping easy and convenient with Mobicred. Mobicred is an online revolving credit facility that allows you to shop online with Dial•a•Bed and repay the amount in monthly instalments."
    // }
  ]

  return (
    <Layout location={location}>
      <SEO
        title={page.title}
        location={location}
        post_id={page.pageId}
        seo={seo}
      />
      <Wrapper>
        <Grid>
          <h1 dangerouslySetInnerHTML={{ __html: page.title }} />
          <PageContent dangerouslySetInnerHTML={{ __html: page.content }} />
        </Grid>
      </Wrapper>
      <Wrapper>
        <Grid cols={3} gutter="10px" align="flex-center">
          {PaymentMethodItems.map((value, key) => {
            // console.log(value)
            return (
              <Block className={"Block-" + key} id={value.id}>
                <GatsbyImage
                  image={value.img}
                  style={{ maxHeight: "50px" }}
                  imgStyle={{ objectFit: "contain" }}
                  placeholder="none"
                  objectFit="contain"
                  objectPosition="50% 50%"
                  alt={value.alt}
                />
                <Description>{value.description}</Description>
              </Block>
            )
          })}
        </Grid>
      </Wrapper>
    </Layout>
  )
}

export default PaymentProviders

const Wrapper = styled.div`
  margin: 40px 0;
  strong {
    font-weight: 500;
  }

  h1 {
    margin-bottom: 20px;
  }

  h3,
  h2 {
    margin: 15px 0;

    strong {
      font-weight: 300;
    }
  }
  .gatsby-image-wrapper {
    margin-bottom: 15px;
    height: 50px;
  }
`
const PageContent = styled.div``

const Block = styled.div`
  padding: 15px;
  border: 1px solid #a8bcd4;
  border-radius: 10px;
  width: 100%;
  text-align: center;
`

const Description = styled.div``

const QUERY = graphql`
  {
    page: localWpGraphQlPages(slug: { eq: "payment-providers" }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      content
      pageId
    }
    ccImage: file(relativePath: { eq: "visa_mastercard.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    feverImage: file(relativePath: { eq: "fevertree.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    payflexImage: file(relativePath: { eq: "payflex_alt.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    zeropayImage: file(relativePath: { eq: "zeropay.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    payjustnowImage: file(relativePath: { eq: "payjustnow.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    payfastEftImage: file(
      relativePath: { eq: "PayFast-Instant-EFT-logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobicredImage: file(relativePath: { eq: "mobicred.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    masterpassImage: file(relativePath: { eq: "masterpass.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ozowImage: file(relativePath: { eq: "ozow.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    floatImage: file(relativePath: { eq: "float.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
